import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import BillingInfo from "./component/billing-info";
import CustomerInfo from "./component/customer-info";
import Delivery from "./component/delivery";
import DesignerInstruction from "./component/designer-instruction";
import Detail from "./component/Detail";
import DriverInstruction from "./component/driver-instruction";
import EditOrderSubmit from "./component/EditOrderSubmit";
import FetchDetail from "./component/FetchDetail";
import GreetingCard from "./component/greeting-card";
import PaymentAndTotal from "./component/payment-and-total";
import Products from "./component/products";
import Recipents from "./component/recipents";
import ResetRecipient from "./component/reset";
import Submit from "./component/Submit";
import Validation from "./component/Validation";
import OrderContext from "./context/order";

const OrderMain = (props) => {
  const { id } = useParams();
  return (
    <OrderContext module={props.module} title={props.title}>
      {props.module === "new-order" && <ResetRecipient />}
      <FetchDetail id={id} module={props.module} />

      {props.module === "edit-order" && <Validation />}
      {props.module === "edit-order" && <Detail />}
      <Recipents />
      <DriverInstruction />
      <Delivery />
      <GreetingCard />
      <Products />
      <DesignerInstruction />
      <PaymentAndTotal module={props.module} />
      <BillingInfo />
      <CustomerInfo />
      {props.module !== "edit-order" && <Submit />}
      {props.module === "edit-order" && <EditOrderSubmit />}
    </OrderContext>
  );
};

export default OrderMain;
