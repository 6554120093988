/* 
  fraud === Fraud Modal
  save === pending modal
  cancel === cancel modal
  approve === approve modal
  validation === validation modal
  add-fund === add-fund
  refund ==== refund modal
* */

import OrderApprove from "Assets/images/order-approve.png";
import OrderCancel from "Assets/images/order-cancel.png";
import OrderSave from "Assets/images/order-save.png";
import { AppContext } from "Context/AppContext";
import { useFormikContext } from "formik";
import React, { useContext, useState } from "react";
import { useOrderAttributeProvider } from "../context/order";
import { useProcessingOrder } from "../hooks/useProcessingOrder";
import CancelOrder from "./CancelOrder";
import Error from "./Error";
import ModalWithNote from "./ModalWithNote";
import ModalWithReasons from "./ModalWithReasons";
import ModalWithRefunds from "./ModalWithRefunds";
import FundModal from "./OrderFund";
import OverrideEditOrderModal from "./OverrideEditOrderModal";
import { ORDER_SOURCE, PAYMENT_METHODS } from "Helpers/Constants";

const EditOrderSubmit = () => {
  const {
    modal,
    setModal,
    orderDetail,
    setOrderDetails,
    summary,
    setSummary,
  } = useOrderAttributeProvider();
  const {
    showErrorModal,
    setShowErrorModal,
    makeOrderFraud,
    cancelOrderCall,
    saveOrder,
    approveOrder,
    validate,
    orderAssignCheck,
    overRideEditOrder,
    cancleOverRideEditOrder,
  } = useProcessingOrder();
  const [fundType, setFundType] = useState("");
  const { setIsLoading, showToastMessage, lastOrderEditPath } = useContext(
    AppContext
  );
  const [allowEdit, setAllowEdit] = useState(true);
  const [allowEditMessage, setAllowEditMessage] = useState("");
  const proceedClickPopup = async () => {
    overRideEditOrder();
    setModal("");
    window.location.reload();
  };

  const formik = useFormikContext();
  const changeValue = (value) => {
    let val = value || "";
    setSummary({
      ...summary,
      add_fund: fundType === "add-fund" ? val : summary.add_fund,
      refund: fundType === "refund" ? val : summary.refund,
    });
  };

  const [reasonOptionValue, setReasonOptionValue] = useState(null);
  const [reasonMessageValue, setReasonMessageValue] = useState("");
  const handleCancelOrder = (val, msg) => {
    if (val && msg) {
      console.log("val , msg", val, msg);
      setReasonOptionValue(parseInt(val));
      setReasonMessageValue(msg);
      setModal("cancel-refund");
    }
  }
  const handleCancelRefundOrder = (optionVal, refundVal) => {
    console.log("optionVal , refundVal", optionVal, refundVal);
    cancelOrderCall(reasonMessageValue, refundVal, optionVal === 2 ? true : false, optionVal, reasonOptionValue);
    setModal("");
  }

  const handleRefundOrder = (val, msg) => {
    if (val && msg) {
      console.log("val , msg", val, msg);
      setReasonOptionValue(parseInt(val));
      setReasonMessageValue(msg);
      setModal("refund-order");
    }
  }

  const handleRefundRefundOrder = (optionVal, refundVal) => {
    summary.refund = fundType === "refund" ? refundVal : summary.refund;
    orderDetail.reason_message = reasonMessageValue;
    orderDetail.reason_message_option = reasonOptionValue;
    orderDetail.refund_flag = optionVal;
    setModal("");
    approveOrder(reasonMessageValue);
  }

  if ((orderDetail?.order_status === "cancel" && formik.values.is_burq_order !== 1) || (orderDetail?.order_status === "cancel" && formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1))
    return (
      <>
        <ModalWithNote
          showModal={modal === "fraud"}
          handleCancelClick={() => setModal("")}
          handleSubmitClick={makeOrderFraud}
          pageTitle="Fraud Order"
        />
      </>
    );

  const order_payment_transactions_data = formik.values?.order_payment_transactions?.filter((val) => val.order_id === 0) || []
  const payment_method = order_payment_transactions_data?.length > 0 && order_payment_transactions_data[0]?.payment_method
  const isDisebled = formik.values.source === ORDER_SOURCE.WALKIN.toLowerCase() && payment_method === PAYMENT_METHODS.PAYABLI ? true : false

  return (
    <>
      <Error
        open={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
      />
      <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
        <div className="mr-4">
          <img
            src={OrderApprove}
            style={{ width: "60px", height: "60px" }}
            alt="Approve Order"
            onClick={async () => {
              let checkOrderAssign = await orderAssignCheck(true);
              if (!checkOrderAssign.data.editFlag) {
                setAllowEdit(checkOrderAssign.data.allowEdit ? false : true);
                setAllowEditMessage(checkOrderAssign.message);
                setModal("override-order");
                return;
              }
              let error = await validate();
              if (error) {
                return;
              }
              let fundType = "";
              if (+parseFloat(summary.add_fund).toFixed(0) > 0) {
                if (formik.values.payment_type !== "credit_card" && formik.values.payment_type !== "house_account" && formik.values.payment_type !== "dove" && formik.values.payment_type !== "cash") {
                  showToastMessage(
                    undefined,
                    `Add fund only available for orders done through credit/debit cards.`,
                    "error",
                    false
                  );
                  return;
                }
                fundType = "add-fund";
              } else if (+parseFloat(summary.refund).toFixed(0) > 0) {
                fundType = "refund";
              } else if (
                parseFloat(summary.add_fund) === 0 &&
                parseFloat(summary.refund) === 0
              ) {
                fundType = "";
              }
              if (fundType) {
                setFundType(fundType);
                if (fundType === "refund") {
                  setModal("refund-reason")
                } else {
                  setModal("fund-approve");
                }
              } else if (orderDetail.order_validations.length !== 0) {
                setModal("override");
              } else {
                approveOrder();
              }
            }}
          />
        </div>

        {/* {formik.values.is_burq_order !== 1 && (
          <div className="mr-4">
            <img
              src={OrderSave}
              alt="Pending order"
              style={{ width: "40px", height: "40px" }}
              onClick={async () => {
                let checkOrderAssign = await orderAssignCheck(true);
                if (!checkOrderAssign.data.editFlag) {
                  setAllowEdit(checkOrderAssign.data.allowEdit ? false : true);
                  setAllowEditMessage(checkOrderAssign.message);
                  setModal("override-order");
                  return;
                }
                let error = await validate();
                if (error) {
                  return;
                }
                let fundType = "";

                if (+parseFloat(summary.add_fund).toFixed(0) > 0) {
                  if (formik.values.payment_type !== "credit_card" && formik.values.payment_type !== "house_account" && formik.values.payment_type !== "dove") {
                    showToastMessage(
                      undefined,
                      `Add fund only available for orders done through credit/debit cards.`,
                      "error",
                      false
                    );
                    return;
                  }
                  fundType = "add-fund";
                } else if (+parseFloat(summary.refund).toFixed(0) > 0) {
                  fundType = "refund";
                } else if (
                  parseFloat(summary.add_fund) === 0 &&
                  parseFloat(summary.refund) === 0
                ) {
                  fundType = "";
                }
                if (fundType) {
                  setFundType(fundType);
                  setModal("fund-save");
                } else {
                  setModal("save");
                }
              }}
            />
          </div>
        )} */}

        {orderDetail?.order_status !== "cancel" && (
          <div>
            <img
              src={OrderCancel}
              // onClick={() => setModal("cancel-confirm")}
              onClick={async () => {
                let checkOrderAssign = await orderAssignCheck(true);
                if (!checkOrderAssign.data.editFlag) {
                  setAllowEdit(checkOrderAssign.data.allowEdit ? false : true);
                  setAllowEditMessage(checkOrderAssign.message);
                  setModal("override-order");
                  return;
                }
                setModal("cancel-confirm");
              }}
              alt="Cancel order"
              style={{ width: "40px", height: "40px" }}
            />
          </div>
        )}
      </div>

      <ModalWithNote
        showModal={modal === "fraud"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={makeOrderFraud}
        pageTitle="Fraud Order"
      />
      <ModalWithReasons
        showModal={modal === "cancel-reason"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={handleCancelOrder}
        pageTitle="Cancel Order"
        placeholder="cancelling"
        totalRefundAmount={formik.values.total_refund_pending}
      />
      <ModalWithRefunds
        showModal={modal === "cancel-refund"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={handleCancelRefundOrder}
        pageTitle="Cancel Order"
        placeholder="cancelling"
        totalRefundAmount={formik.values.total_refund_pending}
        summary={summary}
        setModal={setModal}
      />
      <ModalWithNote
        showModal={modal === "approve-validation"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={approveOrder}
        pageTitle="Override Order"
      />
      <ModalWithNote
        showModal={modal === "save"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={saveOrder}
        pageTitle="Pending Order"
        placeholder="Why is this order pending?"
      />
      <CancelOrder
        showModal={modal === "cancel-confirm"}
        pageTitle="Cancel Order"
        handleCancelClick={() => setModal("")}
        handleSubmitClick={() => setModal("cancel-reason")}
      />
      <FundModal
        open={modal === "fund-save"}
        pageTitle={fundType === "add-fund" ? "Add Fund" : "Refund"}
        value={fundType === "add-fund" ? summary.add_fund : summary.refund}
        onClose={() => setModal("")}
        onChange={changeValue}
        onSave={() => {
          setModal("save");
        }}
      />
      <ModalWithReasons
        showModal={modal === "refund-reason"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={handleRefundOrder}
        pageTitle="Refund"
        placeholder="refunding"
        totalRefundAmount={formik.values.total_refund_pending}
      />
      <ModalWithRefunds
        showModal={modal === "refund-order"}
        handleCancelClick={() => setModal("")}
        handleSubmitClick={handleRefundRefundOrder}
        pageTitle="Refund"
        placeholder="refunding"
        totalRefundAmount={formik.values.total_refund_pending}
        summary={summary}
        setModal={setModal}
      />
      <FundModal
        open={modal === "fund-approve"}
        onClose={() => setModal("")}
        pageTitle={fundType === "add-fund" ? "Add Fund" : "Refund"}
        value={fundType === "add-fund" ? summary.add_fund : summary.refund}
        onChange={changeValue}
        onSave={() => {
          if (orderDetail.order_validations.length !== 0) {
            setModal("override");
          } else {
            if (summary.add_fund > 0 && isDisebled) {
              // setModal("fund-error");
              setModal("")
              showToastMessage(undefined, 'This feature is not available for walk in orders.', "error", false);
            } else {
              approveOrder();
            }
          }
        }}
      />

      <OverrideEditOrderModal
        showModal={modal === "override-order"}
        // handleCancelClick={() => setModal("")}
        handleCancelClick={cancleOverRideEditOrder}
        handleSubmitClick={proceedClickPopup}
        pageTitle="Override Edit Order"
        hideProceedButton={allowEdit}
        message={allowEditMessage}
      />
    </>
  );
};

export default EditOrderSubmit;
